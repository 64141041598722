.cursor-disabled {
  cursor: no-drop;
}

.cursor-pointer {
  cursor: pointer;
}

.dangerous-goods-container {
  font-size: 13px;
  color: #ff9f00;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.dangerous-icon {
  margin-right: 6px;
}

.mb-acknowledged-dialog {
  max-width: unset;
  width: 90%;
}
