@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-semibold.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-regularit.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

:root {
  --sia-primary: #002569;
  --sia-gold: #8a6c4c;
  --sia-yellow: #ff9f00;
  --secondary-100: #c0e1fb;
  --secondary-300: #75bcf7;
  --secondary-500: #489ff3;
  --secondary-700: #3b7fd2;
  --secondary-800: #356ec0;
  --secondary-900: #2b51a0;
  --neutral-50: #fbfbfb;
  --neutral-100: #f6f6f6;
  --neutral-300: #e6e6e6;
  --neutral-400: #c3c3c3;
  --neutral-500: #a5a5a5;
  --neutral-600: #7b7b7b;
  --neutral-700: #676767;
  --neutral-800: #484848; /* default text color */
  --neutral-900: #262626;
  --warning-100: #ffecb3;
  --warning-300: #ffd44e;
  --warning-500: #ffbe03;
  --warning-700: #ff9f00;
  --success-100: #c3e8be;
  --success-300: #6ecb67;
  --success-500: #05b410;
  --success-700: #009300;
  --error-100: #ffc9cc;
  --error-300: #f26561;
  --error-500: #ff200c;
  --error-700: #df000c;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Proxima Nova", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
